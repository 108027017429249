import {Theme} from '@carbon/react';
import './app.scss';
import App from "./mainContent/App";

function GlobalApp() {
    //TODO: environment variables for site under maintenance
    return (
        <Theme theme={'g100'} className={"flex h-full w-full"}>
            {/*<App/>*/}
            <h1 className={"flex grow w-full justify-center self-center text-4xl"}>
                Under Maintenance
            </h1>
        </Theme>
    )
}
export default GlobalApp;
